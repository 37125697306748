body {
  overflow-x: hidden;
  color: black;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: subpixel-antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
