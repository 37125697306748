
body {
    overflow-x: hidden;
    color: black;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: subpixel-antialiased;
}

h1, h2 {
    color: black;
    font-family: 'Montserrat', sans-serif;
}

p {
    margin: 15px 0;
    font-size: 14px;
    line-height: 1.6;
}

.container {
    margin: 0 auto;
    width: 960px;
    padding: 20px 15px;
    position: relative;
}

ul {
    list-style: none;
    padding: 0;
}

#list > li {
    border-bottom: 1px solid #e6e6e6;
    margin: 0 0 30px 0;
}

ul li > h2, h3, h4, h5 {
    color: black;
}

li {
    margin: 0 0 10px 0;
    padding-left: 10px;
    font-size: 14px;
}

a {
    text-decoration: none;
    color: #32329f;
}


/*select end */

@media all and (min-width: 0\0
) and (min-resolution: 0.001dpcm) {
    .select select {
        padding-right: 0;
    }

    .select:after, .select:before {
        display: none;
    }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .container {
        width: 320px;
    }
}

@media screen and (min-width: 768px) {
    .container {
        width: 750px;
    }
}

